import RunRateTypeSchema from '@prisma-zod-schemas/inputTypeSchemas/RunRateTypeSchema';
import ScrapYieldTypeSchema from '@prisma-zod-schemas/inputTypeSchemas/ScrapYieldTypeSchema';
import { z } from 'zod';
import { costBucketsSchema } from '../core';
import { calcReqCostsInputSchema } from '../requirement';

export const opCostsInputSchema = z.object({
  //   qty: number;
  runRate: z.number(),
  runRateType: RunRateTypeSchema,
  setupHrs: z.number(),
  labRunCostPerHr: z.number(),
  labRunCostPerUnit: z.number(),
  labSetupCostPerHr: z.number(),
  burRunCostPerHr: z.number(),
  burSetupCostPerHr: z.number(),
  burRunCostPerUnit: z.number(),
  burCostPerOperation: z.number(),
  //   burPctOfLabRunCost: number;
  //   burPctOfLabSetupCost: number;
  srvChargePerUnit: z.number(),
  srvBaseCharge: z.number(),
  srvMinCharge: z.number(),
  serviceId: z.string().nullish(),
  servicePartId: z.string().nullish(),
  scrapFixedUnits: z.number(),
  scrapYieldPercent: z.number(),
  scrapYieldType: ScrapYieldTypeSchema,
  estLoadSizeQty: z.number().nullable(),
});

/** These should remain the same throughout re-calcs and qty changes for any
 * given source. They are the original vals */
const opCostsBasisInputSchema = z.object({
  basisQty: z.number(),
  basisRunHrs: z.number(),
});

/** derived from basis vals & how much we need to scale up/down for new part qty*/
const opCostsCalcdInputSchema = z.object({
  calcQty: z.number(),
  calcRunHrs: z.number(),
  /** how many op units are needed to make one of the part*/
  qtyPerLineQty: z.number(),
  /** how many op run hrs are needed to make one of the part*/
  runHrsPerLineQty: z.number(),
});

export const calcOpCostsInputSchema = opCostsInputSchema.merge(
  z.object({
    qty: z.number(),
    runHrs: z.number(),
    requirements: z.array(costBucketsSchema),
  }),
);

/** Validate list of op costs inputs with sequenceNum & reqs.pieceNum */
const _calcOpCostsInputsRaw = calcOpCostsInputSchema
  .omit({ requirements: true })
  .merge(
    z.object({
      sequenceNum: z.number(),
    }),
  );

/** Non-nullable calc inputs with nested inputs (no pre-calcd reqs & subOps). Includes sequenceNum & reqs.pieceNum */
export type CalcOpCostsInputsRaw = z.infer<typeof _calcOpCostsInputsRaw> & {
  requirements: (z.infer<typeof _calcReqCostsInputsRaw> & {
    subOpsCosts: CalcOpCostsInputsRaw[];
  })[];
}; // need to do like this for recursive type to work with z.lazy below

const _calcReqCostsInputsRaw = calcReqCostsInputSchema
  .omit({ subOpsCosts: true })
  .merge(
    z.object({
      pieceNum: z.number(),
      //TODO: subcosts as raw inputs
    }),
  );
/** For validating before calc pre-processing (running nested calcs).
 * Includes sequenceNum & reqs.pieceNum */
export const calcOpCostsInputsRawSchema: z.ZodType<CalcOpCostsInputsRaw> =
  _calcOpCostsInputsRaw.extend({
    requirements: z.array(
      _calcReqCostsInputsRaw.merge(
        z.object({
          subOpsCosts: z.lazy(() => calcOpCostsInputsRawSchema.array()),
        }),
      ),
    ),
  });

export type OpCostsInput = z.infer<typeof opCostsInputSchema>;
export type OpCostsBasisInput = z.infer<typeof opCostsBasisInputSchema>;
export type CalcOpCostsInput = z.infer<typeof calcOpCostsInputSchema>;
