import {
  type ConfiguredOpCostsInput,
  type ConfiguredReqCostsInput,
  type CostCalculations,
  type CostingConfigResponse,
  type CostingSrcToOpCostsCalcdInputMap,
  type CostingSrcToOpCostsInputMap,
  type CostingSrcToReqCostsCalcdInputMap,
  type CostingSrcToReqCostsInputMap,
  type CustomerHeaderResponse,
  type FieldPathValue,
  type NegotiatedPriceResponse,
  type Nullable,
  type ObjArrValsToSingle,
  type OpInputsFull,
  type OrderLineCalculations,
  type OrderLineResponse,
  type PartEngineeringOperationResponse,
  type PartEngineeringRequirementResponse,
  type PartEngineeringResponse,
  type PartHistoryValidation,
  type PricingConfigResponse,
  type QuoteLineBaseResponse,
  type QuoteLineCalculations,
  type QuotingConfigResponse,
  type ReqInputsFull,
  type SiteConfigResponse,
  type ValidLastWin,
  type VisDiscountPriceResponse,
  type VisMarketPriceResponse,
  calcOrderLine,
  calcQuoteLine,
  entries,
  getOrSetCallback,
  isFixedPricingStrategy,
  pickInputsFromSources,
  round,
} from '..';
import {
  type CostingConfigOpSources,
  type CostingConfigReqSources,
  type NestedOpCostCalcs,
  calcCostAggs,
  costingConfigToOpInputMap,
  costingConfigToReqInputMap,
  getCostingOpConfig,
  getCostingReqConfig,
} from './costs';

import {
  type ConfigEntities,
  CostInputSources,
  FixedPricingSources,
  type NegotiatedPrice,
  QtyBasisSources,
  ScrapYieldType,
} from '@prisma/client';
import is from '@sindresorhus/is';
import { mapValues, objectify } from 'radash';
import { P, match } from 'ts-pattern';
import type { Merge } from 'type-fest';
import {
  calculateOperationInputQty,
  maybeDivide,
  maybeMultiply,
  maybeParseNum,
  maybeParseNums,
  maybeParseObjVals,
} from './util';

import type { VisDiscountPrice, VisMarketPrice } from '@lib/kysely/visual';
import { orderBy, reduce, sortBy } from 'lodash-es';
import { makeEstOpInputs } from './operation';
import type { QtyPrice } from './pricing/strategies/fixed';
import { extractVisQtyPricing } from './pricing/strategies/fixed/helpers';
import type { QuotingConfigSources } from './quoting-config';
import { makeActReqInputs, makeEstReqInputs } from './requirement';

interface Suggestions {
  suggestedQtys: readonly [number, ...number[]];
  /** Single quoting config consolidated/merged from all entities by their set
   * priorities in `Site.siteConfig.configEntityPriorities. Source fields are
   * still arrays to eventually be resolved against live sources.`*/
  quotingConfigConsolidated: QuotingConfigResponse;
  /** Actual singular quoting config-level (no nested pricing & costing) field sources have been resolved against configured & available live sources*/
  resolvedQuotingConfig: ResolvedQuotingConfig;
  /** Actual input values for the final field sources in `resolvedQuotingConfig` */
  resolvedQuotingInput: ResolvedQuotingInput;
  costingConfigConsolidated: CostingConfigResponse;
  // TODO: cost inputs with sources requirements should have it's config used
  // (currently just has op config used)
  costInputsWithSources: SuggestedOpCostsInput[] | null; // TODO: prob can get rid of null
  resolvedCostInputs: ResolvedOpCostsInput[] | null;
  resolvedCostCalcs: ResolvedCostCalcs | null;
  pricingConfigConsolidated: PricingConfigResponse;
  //  quotingConfigSourcesUsed: QuotingConfigSourcesUsed;
}

export interface PartHistoryCalculations {
  suggestions: Suggestions;
  historicalWorkOrder: {
    //  calcs: PartEngCalculations | null;
    workOrder: PartEngineeringResponse | null;
  };
  recentWorkOrders: {
    //  aggCalcs: null; // TODO
    //  calcs: any[];
    workOrders: PartEngineeringResponse[];
  };
  masterPrime: {
    //  calcs: PartEngCalculations | null;
    masterEng: PartEngineeringResponse | null;
  };
  mastersAll: PartEngineeringResponse[];
  lastOrder: {
    calcs: OrderLineCalculations | null;
    orderLine: ValidLastWin | OrderLineResponse | null;
  };
  lastQuote: {
    calcs: QuoteLineCalculations | null;
    quoteLine: QuoteLineBaseResponse | null;
  };
  // TODO: makes sense to not force users to repeat work for
  // recent lost quotes
  //   recentQuoteEng: {
  //     aggCalcs: null;
  //     quoteEng: PartEngineeringResponse[] | null;
  //   };
  discountPricing: {
    /** map of discount codes (i.e. on cust/quote header) to parsed qty-prices */
    codeMap: Record<string, QtyPrice[]> | null;
    /** Raw input */
    priceList: (VisDiscountPrice | VisDiscountPriceResponse)[] | null;
  };
  marketPricing: {
    /** map of market ids (i.e. on cust/quote header) to parsed qty-prices */
    codeMap: Record<string, QtyPrice[]> | null;
    /** Raw input */
    priceList: (VisMarketPrice | VisMarketPriceResponse)[] | null;
  };
  negotiatedPricing: {
    /** Parsed qty-prices */
    qtyPrices: QtyPrice[] | null;
    /** Raw input */
    priceRecord: NegotiatedPrice | NegotiatedPriceResponse | null;
  };
}

export interface CalcPartHistoryConfigsInput {
  quotingConfigs: Partial<QuotingConfigsByEntity>;
  siteConfig: SiteConfigResponse;
}
export interface CalcPartHistoryQuoteData {
  customerHeader: CustomerHeaderResponse;
}

export const calcPartHistory = (
  partHistory: PartHistoryValidation['data'], //TODO: figure out validation
  configs: CalcPartHistoryConfigsInput,
  quoteData: CalcPartHistoryQuoteData,
): PartHistoryCalculations => {
  const calcsMemo: PartHistCalcsMemo = {
    suggestions: {},
  };

  const memoize = <
    P extends keyof PartHistCalcsMemo | `suggestions.${keyof Suggestions}`,
  >(
    path: P,
    cb: () => FieldPathValue<PartHistoryCalculations, P>,
  ) => {
    return getOrSetCallback(calcsMemo, path, cb);
  };

  // TODO: put this in the suggestions getter and memoize it in this/outer scope
  const makeSuggestionsBase = (
    partHistCalc: PartHistoryCalculations,
  ): SuggestionsBase => {
    const {
      quotingConfigs,
      siteConfig: { configEntityPriorities },
    } = configs;

    const {
      historicalWorkOrder,
      masterPrime: master,
      lastOrder,
      lastQuote,
    } = partHistCalc;

    // TODO: this prossibly doesn't allow mix-n-matching indiv fields between entities
    const quotingConfigConsolidated: QuotingConfigResponse = (() => {
      // a little hacky but need to fit the shape of pickInputsFromSources
      // arg to re-use
      const configSources = mapValues(
        quotingConfigs as QuotingConfigsByEntity, // annoying issue with partials,  https://github.com/rayepps/radash/issues/354
        (config) => {
          return {
            quotingConfig: config,
          };
        },
      );

      const {
        pickedInputs: { quotingConfig },
      } = pickInputsFromSources(
        configSources,
        {
          configEntityPriorities,
        },
        { configEntityPriorities: 'quotingConfig' },
      );
      return quotingConfig ?? configs.siteConfig.quotingConfig;
    })();

    //  const { costingConfig, pricingConfig } = quotingConfigConsolidated;

    // TODO: indiv entity calcs or at least
    // partHistCalc.historicalWorkOrder should be responsible for mapping
    // to cost input fields
    const { workOrder: histWO } = historicalWorkOrder;
    const { masterEng } = master;

    const singleQtyResolvers = mapValues(
      maybeParseObjVals(
        {
          [QtyBasisSources.HIST_WORK_ORDER_ACT]: histWO?.receivedQuantity,
          [QtyBasisSources.HIST_WORK_ORDER_EST]: histWO?.desiredQuantity,
          [QtyBasisSources.MASTER_EST]: masterEng?.desiredQuantity,
          [QtyBasisSources.LAST_ORDER]: lastOrder.calcs?.calcQuantity,
        },
        { defaultVal: null },
      ),
      (val) => (val != null ? ([val] as const) : null),
    );

    const basisQtySrcResolverMap = {
      ...singleQtyResolvers,
      get [QtyBasisSources.LAST_QUOTE]() {
        if (!lastQuote.quoteLine || !lastQuote.calcs?.quantity) {
          return null;
        }
        // TODO(bb): Need to load qty breaks from vis in viaduact, currently we just import lowest brk as single "main line"
        return maybeParseNums(
          [
            lastQuote.calcs.quantity,
            ...(lastQuote.quoteLine?.quantityBreaks.map((qb) => qb.quantity) ??
              []),
          ],
          { defaultVal: null },
        );
      },
      get [QtyBasisSources.FIXED_PRICING]() {
        const {
          pricingConfig: { pricingStrategy, fixedPricingSrc },
        } = quotingConfigConsolidated;

        if (!isFixedPricingStrategy(pricingStrategy) || !fixedPricingSrc) {
          return null;
        }
        const fixedPricingQtyResolverMap = {
          get [FixedPricingSources.DiscountPricing]() {
            const discountCode =
              quoteData.customerHeader.discountCode ??
              quotingConfigConsolidated.discountCodeDefault;

            if (!discountCode) {
              return null;
            }
            const qtys = partHistCalcs.discountPricing.codeMap?.[discountCode]
              ?.map((qp) => qp.qty)
              .filter(Boolean); // ensures positive/ignore default
            // have to make sure we have at least one qty, ts doesn't help with
            // possibly undefined array index access
            if (!qtys?.[0]) {
              return null;
            }
            const [first, ...rest] = qtys;

            return [first, ...rest] as const;
          },
          get [FixedPricingSources.MarketPricing]() {
            const marketCode =
              quoteData.customerHeader.marketCode ??
              quotingConfigConsolidated.marketCodeDefault;
            if (!marketCode) {
              return null;
            }

            const qtys = partHistCalcs.marketPricing.codeMap?.[marketCode]
              ?.map((qp) => qp.qty)
              .filter(Boolean); // ensures positive/ignore default
            // have to make sure we have at least one qty, ts doesn't help with
            // possibly undefined array index access
            if (!qtys?.[0]) {
              return null;
            }
            const [first, ...rest] = qtys;

            return [first, ...rest] as const;
          },
          get [FixedPricingSources.NegotiatedPricing]() {
            const qtys = partHistCalcs.negotiatedPricing.qtyPrices
              ?.map((qp) => qp.qty)
              .filter(Boolean); // ensures positive/ignore default

            if (!qtys?.[0]) {
              return null;
            }
            const [first, ...rest] = qtys;

            return [first, ...rest] as const;
          },
        } as const satisfies Record<
          FixedPricingSources,
          readonly [number, ...number[]] | null
        >;

        return fixedPricingQtyResolverMap[fixedPricingSrc];
      },

      get [QtyBasisSources.CONFIG_DEFAULT]() {
        const [first, ...rest] = quotingConfigConsolidated.basisQtyDefault; // constrained at db lvl so safe to assume always at least one qty here
        return maybeParseNums([first, ...rest]);
      },
    } as const satisfies BasisQtySrcResolverMap;

    // pref figure out type-safe way to know if at least one resolved value will
    // exist or not to re-use for other fields with defaults w/out hard-coding &
    // maintenance. f/e inspect resolverMap[keys] type union & if at least one
    // resolver has non-nullable then we know at least one will exist (assuming
    // src array is typed properly to always include default when needed)
    let resolvedBasisQtys: {
      resolvedBasisQtys: readonly [number, ...number[]];
      resolvedBasisQtySrc: QtyBasisSources;
    } = {
      resolvedBasisQtys: basisQtySrcResolverMap[QtyBasisSources.CONFIG_DEFAULT],
      resolvedBasisQtySrc: QtyBasisSources.CONFIG_DEFAULT,
    };

    for (const src of quotingConfigConsolidated.basisQtySrc.filter(
      (s) => s !== QtyBasisSources.CONFIG_DEFAULT,
    )) {
      const basisQtyInput = basisQtySrcResolverMap[src];
      if (basisQtyInput != null) {
        resolvedBasisQtys = {
          resolvedBasisQtys: basisQtyInput,
          resolvedBasisQtySrc: src,
        };
        break;
      }
    }

    const resolvedQuotingConfig: ResolvedQuotingConfig = {
      basisQtySrc: resolvedBasisQtys.resolvedBasisQtySrc,
    };
    const resolvedQuotingInput: ResolvedQuotingInput = {
      basisQty: resolvedBasisQtys.resolvedBasisQtys,
    };

    let suggestionsBase: SuggestionsBase = {
      histWO,
      masterEng,
      lastOrder,
      lastQuote,
      quotingConfigConsolidated,
      costingConfigConsolidated: quotingConfigConsolidated.costingConfig,
      pricingConfigConsolidated: quotingConfigConsolidated.pricingConfig,
      basisQtySrcToInputsMap: basisQtySrcResolverMap,
      resolvedQuotingConfig,
      resolvedQuotingInput,
      basisEng: null,
      splitEng: null,
    };

    /** PE used for template of ops/reqs needed, should always have
     * master though */
    const atLeastOneEng: {
      splitEng: EngineeringSplit;
      basisEng: PartEngineeringResponse;
      engBasisType: keyof EngineeringSplit; // TODO: need separate eng basis source enum
    } | null = match({
      histWO,
      masterEng,
    })
      .with(
        P.union({ histWO: P.not(P.nullish) }, { masterEng: P.not(P.nullish) }),
        (splitEng) => {
          return {
            splitEng,
            basisEng: splitEng.masterEng ?? splitEng.histWO!, // still need non-null assertion
            engBasisType: splitEng.masterEng
              ? ('masterEng' as const)
              : ('histWO' as const),
          };
        },
      )
      .otherwise(() => null); // TS knows at least one will be defined if not null

    if (atLeastOneEng != null) {
      const {
        splitEng,
        basisEng, // Should always be master unless for some reason we have WO but not master
      } = atLeastOneEng;

      suggestionsBase = {
        ...suggestionsBase,
        splitEng,
        basisEng,
      };
    } else {
      console.warn(
        'missing both master and historic WO for part: ',
        partHistory.partId,
      );
    }

    return suggestionsBase;
  };

  const _round = <T extends null | undefined>(
    val: number | T,
    { min = 2, small = 2 } = {},
  ): number | T => {
    if (val == null) return val;
    return round(val, { min, small });
  };

  let suggestionsBaseMemo: SuggestionsBase;

  const partHistCalcs: PartHistoryCalculations = {
    get suggestions(): Suggestions {
      if (is.undefined(suggestionsBaseMemo)) {
        suggestionsBaseMemo = makeSuggestionsBase(partHistCalcs);
      }

      const {
        masterEng,
        histWO,
        splitEng,
        basisEng,
        lastOrder,
        //   lastQuote,
        quotingConfigConsolidated,
        resolvedQuotingConfig,
        resolvedQuotingInput,
        basisQtySrcToInputsMap,
        costingConfigConsolidated,
        pricingConfigConsolidated,
      } = suggestionsBaseMemo;

      const mainLineBasisQty = resolvedQuotingInput.basisQty[0];

      const suggestions: Suggestions = {
        get quotingConfigConsolidated() {
          return quotingConfigConsolidated;
        },
        get resolvedQuotingConfig(): ResolvedQuotingConfig {
          return resolvedQuotingConfig;
        },
        get resolvedQuotingInput(): ResolvedQuotingInput {
          return resolvedQuotingInput;
        },
        get suggestedQtys() {
          return resolvedQuotingInput.basisQty;
        },
        get costingConfigConsolidated() {
          return costingConfigConsolidated;
        },
        get costInputsWithSources(): SuggestedOpCostsInput[] | null {
          const memo = calcsMemo.suggestions.costInputsWithSources;
          if (!is.undefined(memo)) {
            return memo;
          }
          const ret = (() => {
            if (!splitEng || !basisEng) {
              console.warn('Missing required data to get cost inputs.', {
                costingConfig: costingConfigConsolidated,
                splitEng,
                basisEng,
              });
              return null;
            }

            /* ------------------------
                  Make Operations Inputs
                ------------------------ */
            if (
              histWO &&
              masterEng &&
              histWO.operations.length !== masterEng.operations.length
            ) {
              console.warn('Operations wo/master length mismatch');
            }
            //TODO: radash might have something to simplify this, maybe objectify
            //the entries or normal reduce Object.entries/for..in with assign
            // map sequence nums to wo/master ops so we have quick lookup later
            const seqMap = reduce(
              splitEng,
              (map, pe, _type) => {
                const type = _type as keyof typeof splitEng;
                const ops = pe?.operations;
                if (!ops) {
                  return map;
                }

                for (const op of ops) {
                  const cur = map[op.sequenceNum] ?? {};
                  map[op.sequenceNum] = { ...cur, [type]: op };
                }

                return map;
              },
              {} as Record<
                number,
                Partial<
                  Record<
                    keyof typeof splitEng,
                    PartEngineeringOperationResponse
                  >
                >
              >,
            );

            // for calculating scrap and qty in opCostsInputs
            const opInputQtyMap: { [key: string]: number | null } = {
              [CostInputSources.HIST_WORK_ORDER_ACT]: null,
              [CostInputSources.HIST_WORK_ORDER_EST]: null,
              [CostInputSources.MASTER_EST]: null,
              [CostInputSources.CONFIG_DEFAULT]: null,
              [CostInputSources.MANUAL_ENTRY]: null,
            } as const satisfies Record<CostInputSources, number | null>;

            const sortedOpsBySequenceNumReversed = orderBy(
              basisEng.operations,
              'sequenceNum',
              'desc',
            );

            const opCostsInputs: SuggestedOpCostsInput[] =
              sortedOpsBySequenceNumReversed.map((basisOp) => {
                if (!seqMap[basisOp.sequenceNum]) {
                  console.warn(
                    'missing work order op for master op, using master only for calcs',
                    basisOp,
                  );
                }
                const histOp = seqMap[basisOp.sequenceNum]?.histWO ?? null;
                const masterOp = seqMap[basisOp.sequenceNum]?.masterEng ?? null;

                type SplitEngType = 'workOrder' | 'master';
                const splitOp: Record<
                  SplitEngType,
                  PartEngineeringOperationResponse | null
                > = { workOrder: histOp, master: masterOp };

                // map of piece nums to wo/master reqs so we have quick lookup later
                const pieceMap: Record<
                  number,
                  Record<
                    SplitEngType,
                    PartEngineeringRequirementResponse | undefined
                  >
                > = {};

                for (const [type, op] of entries(splitOp)) {
                  for (const req of op?.requirements ?? []) {
                    const cur = pieceMap[req.pieceNum] ?? {};
                    // careful to assign explicitly and not spread or loses key's
                    // type safety somehow
                    cur[type] = req;
                    pieceMap[req.pieceNum] = cur;
                  }
                } //TODO: with subs

                const opSrcToInputMap: CostingSrcToOpCostsInputMap = {
                  // TODO: put this in makeActSrcToInputMap
                  get [CostInputSources.HIST_WORK_ORDER_ACT]() {
                    if (!histOp) return null;

                    const baseVals = maybeParseObjVals(
                      {
                        basisQty: histOp.actCompletedQty,
                        basisRunHrs: histOp.actRunHrs,
                        setupHrs: histOp.actSetupHrs,
                        labRunCostPerHr: histOp.actLabRunCostPerHr,
                        labSetupCostPerHr: histOp.actLabSetupCostPerHr,
                        labRunCostPerUnit: null,
                        burRunCostPerHr: histOp.actBurRunCostPerHr,
                        burSetupCostPerHr: histOp.actBurSetupCostPerHr,
                        burRunCostPerUnit: histOp.actBurRunCostPerHr,
                        burCostPerOperation: histOp.actBurRunCostPerHr,
                        srvChargePerUnit: null,
                        srvBaseCharge: null,
                        srvMinCharge: null,
                        scrapYieldPercent: null,
                        scrapFixedUnits: null,
                        estLoadSizeQty: null,
                      },
                      { defaultVal: null },
                    );

                    const { basisRunHrs, basisQty } = baseVals;

                    return {
                      ...baseVals,
                      ...{
                        runRate:
                          basisRunHrs === 0
                            ? null //TODO: verify null is better than 0 (some act run hrs are 0 when est has run rate)
                            : _round(maybeDivide(basisQty, basisRunHrs)) ??
                              null, //TODO: possible this is diff depending on run type
                        runRateType: histOp.runRateType,
                        scrapYieldType: histOp.scrapYieldType,
                        serviceId: null,
                        servicePartId: null,
                      },
                    };
                  },
                  [CostInputSources.HIST_WORK_ORDER_EST]:
                    (histOp && makeEstOpInputs(histOp)) ?? null,

                  [CostInputSources.MASTER_EST]:
                    (masterOp && makeEstOpInputs(masterOp)) ?? null,

                  [CostInputSources.CONFIG_DEFAULT]: makeDefaultOpInputs(
                    costingConfigConsolidated,
                  ),
                  [CostInputSources.MANUAL_ENTRY]: null,
                };

                const {
                  pickedInputs: pickedOpInputs,
                  configUsed: opConfigUsed,
                } = pickInputsFromSources(
                  opSrcToInputMap,
                  getCostingOpConfig(costingConfigConsolidated),
                  costingConfigToOpInputMap,
                );
                // Ideally we wouldn't need line qtys for cost calcs but there are
                // still some holes & unknowns in our costing algo.

                // Assuming we only care about the main line qty
                const lineQtySrcResolverMap = {
                  [CostInputSources.MASTER_EST]:
                    basisQtySrcToInputsMap[QtyBasisSources.MASTER_EST]?.[0] ??
                    null,
                  [CostInputSources.HIST_WORK_ORDER_ACT]:
                    basisQtySrcToInputsMap[
                      QtyBasisSources.HIST_WORK_ORDER_ACT
                    ]?.[0] ?? null,
                  [CostInputSources.HIST_WORK_ORDER_EST]:
                    basisQtySrcToInputsMap[
                      QtyBasisSources.HIST_WORK_ORDER_EST
                    ]?.[0] ?? null,
                  [CostInputSources.CONFIG_DEFAULT]: null,
                  [CostInputSources.MANUAL_ENTRY]: null,
                } as const satisfies Readonly<
                  Record<CostInputSources, number | null>
                >;

                const srcCalcdOpInputsMap: CostingSrcToOpCostsCalcdInputMap =
                  mapValues(opSrcToInputMap, (input, src) => {
                    if (!input) return null;
                    const {
                      basisQty,
                      basisRunHrs,
                      runRateType,
                      scrapYieldType,
                      scrapFixedUnits,
                      scrapYieldPercent,
                    } = input;
                    // for this op for this src what was the qty of the actual part made
                    const srcLineQty = lineQtySrcResolverMap[src];

                    const qtyPerLineQty =
                      maybeDivide(basisQty, srcLineQty) ?? null;
                    const runHrsPerLineQty =
                      maybeDivide(basisRunHrs, srcLineQty) ?? null;
                    const qtyPerRunHr =
                      basisRunHrs === 0
                        ? 0
                        : maybeDivide(basisQty, basisRunHrs) ?? null;

                    // the output qty of an operation is equivalent to the inputQty of the next operation
                    // if inputQty is null (final operation), the qty is the desired lineQty
                    const calcQty = opInputQtyMap[src]
                      ? opInputQtyMap[src]
                      : srcLineQty ?? null;
                    const calcStartQty =
                      calculateOperationInputQty(
                        calcQty,
                        scrapFixedUnits,
                        scrapYieldType,
                        scrapYieldPercent,
                      ) ?? null;
                    opInputQtyMap[src] = calcStartQty;
                    const calcRunHrs =
                      qtyPerRunHr === 0
                        ? 0
                        : maybeDivide(calcQty, qtyPerRunHr) ?? null;

                    return {
                      qtyPerLineQty: _round(qtyPerLineQty),
                      runHrsPerLineQty: _round(runHrsPerLineQty),
                      qtyPerRunHr: _round(qtyPerRunHr),
                      calcQty: _round(calcQty),
                      calcRunHrs: _round(calcRunHrs, { min: 2, small: 2 }),
                      runRateType,
                      scrapYieldType,
                      calcStartQty,
                    };
                  });

                // TODO: put this in calcRequirements/similar
                /* ------------------------
                    Make Requirements Inputs
                  ------------------------ */
                const reqs: SuggestedReqCostsInput[] = basisOp.requirements.map(
                  (basisReq) => {
                    const histWOReq =
                      pieceMap[basisReq.pieceNum]?.workOrder ?? null; // TODO: figure out subEng/assembly recursion
                    const masterReq =
                      pieceMap[basisReq.pieceNum]?.master ?? null;

                    const histActInputs =
                      histWOReq && makeActReqInputs(histWOReq);
                    const histEstInputs =
                      histWOReq && makeEstReqInputs(histWOReq);
                    const masterEstInputs =
                      masterReq && makeEstReqInputs(masterReq);
                    const defaultInputs = makeDefaultReqInputs(
                      costingConfigConsolidated,
                    );

                    const reqSrcToInputMap: CostingSrcToReqCostsInputMap = {
                      [CostInputSources.HIST_WORK_ORDER_ACT]: histActInputs,
                      [CostInputSources.HIST_WORK_ORDER_EST]: histEstInputs,
                      [CostInputSources.MASTER_EST]: masterEstInputs,
                      [CostInputSources.CONFIG_DEFAULT]: defaultInputs,
                      [CostInputSources.MANUAL_ENTRY]: null,
                    };

                    const {
                      pickedInputs: pickedReqInputs,
                      configUsed: reqConfigUsed,
                    } = pickInputsFromSources(
                      reqSrcToInputMap,
                      getCostingReqConfig(costingConfigConsolidated),
                      costingConfigToReqInputMap,
                    );
                    // TODO: these calcing for all sources should be in a getter
                    const srcCalcdReqInputsMap: CostingSrcToReqCostsCalcdInputMap =
                      mapValues(reqSrcToInputMap, (input, src) => {
                        if (!input) return null;
                        const { basisQty, unitsPerPiece, fixedQty } = input;

                        // for this req for this src what was the qty of the actual
                        // part made
                        const srcLineQty = lineQtySrcResolverMap[src];

                        // for this req for this src what was the qty of the op
                        const srcOp = opSrcToInputMap[src];
                        const srcOpQty = srcOp?.basisQty;

                        const qtyPerLineQty =
                          maybeDivide(basisQty, srcLineQty) ?? null;
                        const qtyPerOpQty =
                          maybeDivide(basisQty, srcOpQty) ?? null;

                        // Both of these *should* be the same? but the one from op
                        // could be more reliable long-term if we ever implement
                        // "step-units" f/e if some op qty needs to be whole
                        // numbers only
                        //TODO: figure out unitsPerPieceType and how it affects this calculation
                        let calcQtyFromOp =
                          maybeMultiply(opInputQtyMap[src], unitsPerPiece) ??
                          null;
                        // requirements also have a "fixed qty" that is always included
                        if (calcQtyFromOp && fixedQty) {
                          calcQtyFromOp += fixedQty;
                        }
                        const calcQtyFromLine =
                          maybeMultiply(srcLineQty, qtyPerLineQty) ?? null;
                        // TODO: pref to use rounding proxy like margin calcs so internal calcs
                        // can still be precise
                        return {
                          calcQtyFromOp: _round(calcQtyFromOp),
                          calcQtyFromLine: _round(calcQtyFromLine),
                          qtyPerLineQty: _round(qtyPerLineQty),
                          qtyPerOpQty: _round(qtyPerOpQty),
                        };
                      });

                    const mergedReqInputsSrcMap: ParsedReqCostsInputSrcMap =
                      mapValues(reqSrcToInputMap, (configInputs, src) => {
                        const srcCalcdInputs = srcCalcdReqInputsMap[src];
                        // will be null if configInputs are null
                        if (!configInputs || !srcCalcdInputs) return null;

                        return {
                          ...configInputs,
                          ...srcCalcdInputs,
                          pieceNum: basisReq.pieceNum,
                          reqPartId: basisReq.reqPartId,
                        };
                      });

                    // picked inputs are merged with the calcd matching the basis
                    // qty src used
                    // .i.e.  if basis qty src was act historic, then we will scale with the
                    // actual needed req qty consumed to make the actual qty of the op/line
                    const pickedFullReqInput: ResolvedReqCostsInput = {
                      ...pickedReqInputs,
                      ...((reqConfigUsed.reqBasisQtySrc &&
                        srcCalcdReqInputsMap[reqConfigUsed.reqBasisQtySrc]) ?? {
                        // default just nulled fields
                        calcQtyFromOp: null,
                        calcQtyFromLine: null,
                        qtyPerLineQty: null,
                        qtyPerOpQty: null,
                      }),
                      pieceNum: basisReq.pieceNum,
                      reqPartId: basisReq.reqPartId,
                    };

                    const reqCostInput: SuggestedReqCostsInput = {
                      resolvedInput: pickedFullReqInput,
                      sources: mergedReqInputsSrcMap,
                      resolvedConfig: reqConfigUsed,
                      pieceNum: basisReq.pieceNum,
                    };

                    return reqCostInput;
                  },
                );

                const pickedOpCalcdInputs = (() => {
                  const picked =
                    opConfigUsed.runHrsSrc &&
                    srcCalcdOpInputsMap[opConfigUsed.runHrsSrc];

                  if (!picked) {
                    console.warn(
                      'missing op calcd inputs for op sequence ',
                      basisOp.sequenceNum,
                    );
                  }

                  return (
                    picked ?? {
                      calcQty: null,
                      calcRunHrs: null,
                      qtyPerLineQty: null,
                      runHrsPerLineQty: null,
                      qtyPerRunHr: null,
                      calcStartQty: null,
                    }
                  );
                })();

                const pickedFullOpInputs: Merge<
                  ResolvedOpCostsInput,
                  { requirements: SuggestedReqCostsInput[] }
                > = {
                  ...pickedOpInputs,
                  ...pickedOpCalcdInputs,
                  requirements: reqs,
                  sequenceNum: basisOp.sequenceNum,
                  resourceId: basisOp.resourceId,
                };

                const opCostsInput: SuggestedOpCostsInput = {
                  resolvedInput: pickedFullOpInputs,
                  sources: mapValues(opSrcToInputMap, (configInputs, src) => {
                    const srcCalcdInputs = srcCalcdOpInputsMap[src];
                    if (!configInputs || !srcCalcdInputs) return null;
                    return {
                      ...configInputs,
                      ...srcCalcdInputs,
                      requirements: reqs
                        .map((r) => r.sources[src])
                        .filter((r): r is ResolvedReqCostsInput => r != null),
                      resourceId: basisOp.resourceId,
                      sequenceNum: basisOp.sequenceNum,
                    };
                  }),
                  resolvedConfig: opConfigUsed,
                  resourceId: basisOp.resourceId,
                  sequenceNum: basisOp.sequenceNum,
                };
                return opCostsInput;
              });

            return opCostsInputs.toReversed();
          })();

          calcsMemo.suggestions.costInputsWithSources = ret;
          return ret;
        },
        get resolvedCostInputs(): ResolvedOpCostsInput[] | null {
          const memo = calcsMemo.suggestions.resolvedCostInputs;
          if (!is.undefined(memo)) {
            return memo;
          }
          const costInputs = suggestions.costInputsWithSources;
          if (!costInputs) return null;
          const pickedCosts = costInputs.map((opCostsInput) => {
            const { requirements, ...restOpCostsInput } =
              opCostsInput.resolvedInput;

            return {
              ...restOpCostsInput,
              requirements: requirements.map(
                (reqCostsInput) => reqCostsInput.resolvedInput,
              ),
            };
          });

          calcsMemo.suggestions.resolvedCostInputs = pickedCosts;
          return pickedCosts;
        },
        get resolvedCostCalcs(): ResolvedCostCalcs | null {
          const memo = calcsMemo.suggestions.resolvedCostCalcs;
          if (!is.undefined(memo)) {
            return memo;
          }
          const costInputs = suggestions.resolvedCostInputs;
          if (!costInputs) {
            console.warn(
              'Missing cost inputs in suggestions.pickedCostCalcs, cannot pick cost calcs',
            );
            return null;
          }
          const costCalcs = calcCostAggs(costInputs);
          if (!costCalcs) {
            // invalid input
            console.warn(
              'Invalid cost inputs in suggestions.pickedCostCalcs, cannot pick cost calcs',
            );
            return null;
          }
          const { agg: calcsAgg, nested } = costCalcs;

          const unitCosts =
            mainLineBasisQty && mainLineBasisQty !== 0
              ? mapValues(calcsAgg, (cost) => cost / mainLineBasisQty)
              : null;

          const pickedCostCalcs = { totals: calcsAgg, unit: unitCosts, nested };

          calcsMemo.suggestions.resolvedCostCalcs = pickedCostCalcs;
          return pickedCostCalcs;
        },
        get pricingConfigConsolidated(): PricingConfigResponse {
          return pricingConfigConsolidated;
        },
      };

      return suggestions;
    },
    /** Not necessarily the last so users can choose */
    get historicalWorkOrder() {
      return {
        //   calcs: null,
        workOrder: partHistory.engineering.workOrders[0] ?? null,
      };
    },
    get recentWorkOrders() {
      return {
        //   aggCalcs: null,
        //   calcs: [],
        workOrders: partHistory.engineering.workOrders,
      };
    },
    /** Master used as basis & master eng source */
    get masterPrime() {
      // Trying out an abstraction for memoizing getters, might revert or switch to decorators
      return memoize('masterPrime', () => {
        const {
          engineering: { masters },
        } = partHistory;
        // TODO: user selectable & pass into calc but this still seems
        //  reasonable as default
        const firstWithCosts = sortBy(masters, [
          'lotId',
          'splitId',
          'subId',
        ]).find((m) =>
          // lower, especially all '0' ids appear more reliable
          [
            m.estimatedLaborCost,
            m.estimatedMaterialCost,
            m.estimatedBurdenCost,
            m.estimatedServiceCost,
          ].some((val) => maybeParseNum(val)),
        );

        const pickedEng = firstWithCosts ?? masters[0] ?? null;

        return {
          masterEng: pickedEng,
        };
      });
    },
    get mastersAll() {
      return partHistory.engineering.masters;
    },
    get lastOrder() {
      const memo = calcsMemo.lastOrder;
      if (!is.undefined(memo)) {
        return memo;
      }
      const { lastWin } = partHistory; // TODO: we should loosen validation since it's not required for calc anymore, maybe narrow as union - must have at least one src etc, or always return so we can empty cost inputs
      const ret = {
        calcs: lastWin ? calcOrderLine(lastWin) : null,
        orderLine: lastWin ?? null,
      };
      calcsMemo.lastOrder = ret;
      return ret;
    },
    get lastQuote() {
      const memo = calcsMemo.lastQuote;
      if (!is.undefined(memo)) {
        return memo;
      }
      const { mostRecentQuoteLine } = partHistory;
      const ret = {
        calcs: mostRecentQuoteLine
          ? calcQuoteLine(mostRecentQuoteLine) ?? null
          : null,
        quoteLine: mostRecentQuoteLine ?? null,
      };
      calcsMemo.lastQuote = ret;
      return ret;
    },
    get discountPricing() {
      return memoize('discountPricing', () => {
        const { discountPricing } = partHistory;

        const codeMap =
          discountPricing &&
          objectify(
            discountPricing,
            (dp) => dp.discount_code.trim(),
            extractVisQtyPricing,
          );

        return {
          codeMap,
          priceList: discountPricing,
        };
      });
    },
    get marketPricing() {
      return memoize('marketPricing', () => {
        const { marketPricing = null } = partHistory;

        const codeMap =
          marketPricing &&
          objectify(
            marketPricing,
            (mp) => mp.market_id.trim(),
            extractVisQtyPricing,
          );

        return {
          codeMap,
          priceList: marketPricing,
        };
      });
    },
    get negotiatedPricing() {
      return memoize('negotiatedPricing', () => {
        const { negotiatedPrice } = partHistory;
        let qtyPrices: QtyPrice[] | null = null;
        if (negotiatedPrice) {
          qtyPrices = [];
          const { qtyBreaks } = negotiatedPrice;
          const defaultPrice =
            maybeParseNum(negotiatedPrice.defaultPrice) ?? null;

          defaultPrice && qtyPrices.push({ qty: 1, price: defaultPrice });
          // !TODO: include brk pricing in negotiated db schema and parse here
        }
        return { qtyPrices, priceRecord: negotiatedPrice };
      });
    },
  };
  return partHistCalcs;
};

const makeDefaultOpInputs = (
  config: CostingConfigResponse,
): Nullable<ConfiguredOpCostsInput> => {
  return {
    ...maybeParseObjVals(
      {
        basisQty: null,
        basisRunHrs: null,
        runRate: null,
        setupHrs: null,
        labRunCostPerHr: null,
        labSetupCostPerHr: null,
        labRunCostPerUnit: null,
        burRunCostPerHr: null,
        burSetupCostPerHr: null,
        burRunCostPerUnit: null,
        burCostPerOperation: null,
        srvChargePerUnit: null,
        srvBaseCharge: config.srvBaseChargeDefault,
        srvMinCharge: config.srvMinChargeDefault,
        scrapFixedUnits: null,
        scrapYieldPercent: null,
        scrapYieldType: ScrapYieldType.SCRAP,
        estLoadSizeQty: null,
      },
      { defaultVal: null },
    ),
    runRateType: null,
    serviceId: null,
    servicePartId: null,
  };
};

const makeDefaultReqInputs = (
  config: CostingConfigResponse,
): Nullable<ConfiguredReqCostsInput> => {
  return {
    basisQty: null,
    fixedQty: null,
    matUnitCost: null,
    matFixedCost: null,
    matMinCost: maybeParseNum(config.matMinCostDefault) ?? null,
    labUnitCost: null,
    burUnitCost: null,
    srvUnitCost: null,
    burPct: null,
    burPerUnit: null,
    usageUM: null,
    unitsPerPieceType: null,
    unitsPerPiece: null,
  };
};

/**
 * Resolvers for *suggested/init* quantities
 * Config default is contrained to always have at least one qty element */
interface BasisQtySrcResolverMap
  extends Readonly<
    Record<QtyBasisSources, readonly [number, ...number[]] | null>
  > {
  readonly [QtyBasisSources.CONFIG_DEFAULT]: readonly [number, ...number[]];
}

export type QuotingConfigsByEntity = Record<
  ConfigEntities,
  QuotingConfigResponse | undefined | null
>;

type PartHistCalcsMemo = Merge<
  Partial<PartHistoryCalculations>,
  {
    suggestions: Partial<Suggestions>;
  }
>;

// TODO: prob could use source enum vals for keys
type EngineeringSplit = {
  histWO: PartEngineeringResponse | null;
  masterEng: PartEngineeringResponse | null;
};

interface SuggestionsBase {
  masterEng: PartEngineeringResponse | null;
  histWO: PartEngineeringResponse | null;
  basisEng: PartEngineeringResponse | null;
  splitEng: EngineeringSplit | null;
  lastOrder: {
    calcs: OrderLineCalculations | null;
    orderLine: ValidLastWin | OrderLineResponse | null;
  };
  lastQuote: {
    calcs: QuoteLineCalculations | null;
    quoteLine: QuoteLineBaseResponse | null;
  };
  quotingConfigConsolidated: QuotingConfigResponse;
  resolvedQuotingConfig: ResolvedQuotingConfig;
  resolvedQuotingInput: ResolvedQuotingInput;
  costingConfigConsolidated: CostingConfigResponse;
  pricingConfigConsolidated: PricingConfigResponse;
  basisQtySrcToInputsMap: BasisQtySrcResolverMap;
}

// calc validation will handle null cost inputs before passing to ql calcs so we
// can still give the user what we have and they fill in the blanks
/** Contains resolved config, available sources, final resolved input vals, and metadata */
export interface SuggestedReqCostsInput {
  resolvedInput: ResolvedReqCostsInput;
  sources: ParsedReqCostsInputSrcMap;
  resolvedConfig: ResolvedCostingReqConfig;
  pieceNum: number;
}

/** Contains resolved config, available sources, final resolved input vals, and metadata */
export interface SuggestedOpCostsInput {
  resolvedInput: Merge<
    ResolvedOpCostsInput,
    { requirements: SuggestedReqCostsInput[] }
  >;
  sources: ResolvedOpCostsInputSrcMap;
  resolvedConfig: ResolvedCostingOpConfig;
  resourceId: string;
  sequenceNum: number;
}

export interface ResolvedOpCostsInput extends Nullable<OpInputsFull> {
  resourceId: string;
  sequenceNum: number;
  requirements: ResolvedReqCostsInput[];
}

export type ResolvedOpCostsInputSrcMap = Nullable<
  Record<CostInputSources, ResolvedOpCostsInput>
>; //TODO: this should be mapped by seq num

export interface ResolvedReqCostsInput extends Nullable<ReqInputsFull> {
  pieceNum: number;
  reqPartId: string | null;
  reqPartDescription?: string | null;
}
export type ParsedReqCostsInputSrcMap = Nullable<
  Record<CostInputSources, ResolvedReqCostsInput>
>; // TODO: this should be mapped by piece num

export interface ResolvedQuotingInput {
  readonly basisQty: readonly [number, ...number[]];
}

export interface ResolvedQuotingConfig
  extends Nullable<
    Omit<ObjArrValsToSingle<QuotingConfigSources>, 'basisQtySrc'>
  > {
  basisQtySrc: QtyBasisSources;
}

type ResolvedCostingOpConfig = Nullable<
  ObjArrValsToSingle<CostingConfigOpSources>
>;
type ResolvedCostingReqConfig = Nullable<
  ObjArrValsToSingle<CostingConfigReqSources>
>;

export interface ResolvedCostCalcs {
  totals: CostCalculations;
  unit: CostCalculations | null;
  nested: NestedOpCostCalcs;
}
