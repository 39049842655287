import type { PartEngineeringOperationResponse } from '../responses/part-engineering';
import type { Nullable } from '../util';
import type { ConfiguredOpCostsInput } from './costs';
import { maybeParseObjVals } from './util';

/** extracts op cost inputs  */
export const makeEstOpInputs = (
  // TODO: should take optional op and make nullable default
  op: PartEngineeringOperationResponse,
): Nullable<ConfiguredOpCostsInput> => {
  const baseVals = maybeParseObjVals(
    {
      basisQty: op.estEndQty,
      basisRunHrs: op.estRunHrs,
      runRate: op.runRate,
      setupHrs: op.estSetupHrs,
      labRunCostPerHr: op.estLabRunCostPerHr,
      labSetupCostPerHr: op.estLabSetupCostPerHr,
      labRunCostPerUnit: op.serviceId == null ? op.estRunCostPerUnit : 0, // confirmed serviceId is discriminator for runCostPerUnit being for services or labor for all current vis data
      burRunCostPerHr: op.estBurRunCostPerHr,
      burSetupCostPerHr: op.estBurSetupCostPerHr,
      burRunCostPerUnit: op.estBurRunCostPerUnit,
      burCostPerOperation: op.estBurPerOperation,
      srvChargePerUnit: op.serviceId != null ? op.estRunCostPerUnit : 0, // confirmed serviceId is discriminator for runCostPerUnit for services or labor for all current vis data
      srvBaseCharge: op.estSrvBaseCharge,
      srvMinCharge: op.estSrvMinCharge,
      scrapFixedUnits: op.scrapFixedUnits,
      scrapYieldPercent: op.scrapYieldPercent,
      estLoadSizeQty: op.estLoadSizeQty,
    },
    { defaultVal: null },
  );

  return {
    ...baseVals,
    ...{
      runRateType: op.runRateType,
      scrapYieldType: op.scrapYieldType,
      serviceId: op.serviceId, // TODO: could potentially cause problems if serviceId and associated srv costs don't match up between sources
      servicePartId: op.servicePartId,
    },
  };
};
