import { RunRateType } from '@prisma/client';
import {
  type CostCalculations,
  aggCostCalcs,
  calcCosts,
  zeroCostCalcs,
} from '../core';
import type { CalcOpCostsInput } from './zod';

export interface OpCostCalculations extends CostCalculations {
  /** Totals without reqs ("atl" costs)*/
  opSplit: CostCalculations;
  /** req totals */
  reqSplit: CostCalculations;
}

export const calcOperationCosts = (
  opCostsInput: CalcOpCostsInput,
): OpCostCalculations => {
  const {
    qty,
    runRate,
    runRateType,
    setupHrs,
    labRunCostPerHr,
    labRunCostPerUnit,
    labSetupCostPerHr,
    burRunCostPerHr,
    burSetupCostPerHr,
    burRunCostPerUnit,
    burCostPerOperation,
    //   burPctOfLabRunCost: number;
    //   burPctOfLabSetupCost: number;
    srvChargePerUnit,
    srvBaseCharge,
    srvMinCharge,
    estLoadSizeQty,
    requirements: reqInputs,
  } = opCostsInput;

  /* 
   ------------------------
   ASSUMPTIONS:
     1. setup time is fixed
     2. run Hrs scales with order qty
     3. different run rate types are still calculated the same way (or consumer
        might have to handle)
        - the unit of per-unit costs is always the quantity (not related to one of
          the sides of run type i.e. using load_size_qty for DAYS/LOAD run types)

   KNOWN LIMITATIONS/VARIATIONS:
     1. Ignoring burden percent of run & setup, thought it was figured out and
        not positive anymore
     2. Ignoring scrap (easy to add though)
     3. Ignoring load_size_qty and minimum_move_qty completely; hopefully
        covering with assumption #3
     4. Assumes service costs scale with quantity
         - reality many will after the min charge is met, but many provided
           est service costs == min charge so we don't know the
           actual cost/unit unless provided est/act is higher than provided min
         - doesn't account for vendor quantity breaks


    OPEN QUESTIONS:
       1. What is the difference between run_cost_per_unit and run_cost_per_Hr
       when run_rate_type is PCS/HR?
       2. Are per-unit costs supposed to be/usually/site-dependent for service costs?

   ------------------------
  */

  /* --- Run Costs --- */

  // run/hr

  //   const trueBurRunCostPerHr =
  //     burRunCostPerHr + burPctOfLabRunCost * labRunCostPerHr; //TODO: verify
  const contrbRunCostPerHr = labRunCostPerHr;
  const grossRunCostPerHr = labRunCostPerHr + burRunCostPerHr;
  //   const grossRunCostPerHr = labRunCostPerHr + trueBurRunCostPerHr;

  let runHrs = 0;

  // The visual "run_hrs" field in the DB seems to be calculated and rounded,
  // and using it straight up seems to introduce marginal errors in the calculations.
  // Thus, calculate the run hours based on the quantity and provided run rate data.

  const numberOfLoads = Math.ceil(qty / estLoadSizeQty!);
  switch (runRateType) {
    case RunRateType.PCS_PER_HR:
      runHrs = runRate ? qty / runRate : 0;
      break;
    case RunRateType.HRS_PER_PC:
      runHrs = runRate * qty;
      break;
    case RunRateType.DAYS_PER_PC:
      runHrs = runRate * 24 * qty;
      break;
    case RunRateType.HRS_PER_LOAD:
      runHrs = runRate * numberOfLoads;
      break;
    case RunRateType.DAYS_PER_LOAD:
      runHrs = runRate * 24 * numberOfLoads;
      break;
    case RunRateType.PCS_PER_MIN:
      runHrs = runRate ? qty / runRate / 60 : 0;
      break;
    case RunRateType.PCS_PER_DAY:
      runHrs = runRate ? (qty / runRate) * 24 : 0;
      break;
    case RunRateType.LOADS_PER_HR:
      runHrs = runRate ? numberOfLoads / runRate : 0;
      break;
    case RunRateType.LOADS_PER_DAY:
      runHrs = runRate ? (numberOfLoads / runRate) * 24 : 0;
      break;
    default:
      break;
  }

  // run/hr totals
  const [
    labRunCostHrlyTotal,
    burRunCostHrlyTotal,
    contrRunCostHrlyTotal,
    grossRunCostHrlyTotal,
  ] = calcTotals(
    [
      labRunCostPerHr,
      burRunCostPerHr,
      // trueBurRunCostPerHr,
      contrbRunCostPerHr,
      grossRunCostPerHr,
    ],
    runHrs,
  );

  // run/unit
  //   const labRunCostPerUnit = labRunCostPerUnit; //TODO: just for the getter
  //   const trueBurRunCostPerUnit =
  //   burCostPerUnit + burPctOfLabRunCost * labRunCostPerUnit; //TODO: verify
  const contrbRunCostPerUnit = labRunCostPerUnit;
  //   const burRunCostPerUnit = burRunCostPerUnit;
  const grossRunCostPerUnit = contrbRunCostPerUnit + burRunCostPerUnit;

  // run/unit totals
  const [
    labRunCostByUnitTotal,
    burRunCostByUnitTotal,
    contrRunCostByUnitTotal,
    runCostByUnitTotal,
  ] = calcTotals(
    [
      labRunCostPerUnit,
      burRunCostPerUnit,
      contrbRunCostPerUnit,
      grossRunCostPerUnit,
    ],
    qty,
  );

  /* --- Setup Costs --- */

  // setup/hr

  //   const trueBurSetupCostPerHr =
  //     burSetupCostPerHr + burPctOfLabSetupCost * labSetupCostPerHr; //TODO: verify
  const contrbSetupCostPerHr = labSetupCostPerHr;
  const grossSetupCostPerHr = labSetupCostPerHr + burSetupCostPerHr;

  // setup/hr totals
  const [
    labSetupCostHrlyTotal,
    burSetupCostHrlyTotal,
    contrSetupCostHrlyTotal,
    grossSetupCostHrlyTotal,
  ] = calcTotals(
    [
      labSetupCostPerHr,
      burSetupCostPerHr,
      contrbSetupCostPerHr,
      grossSetupCostPerHr,
    ],
    setupHrs,
  );

  // setup/unit
  const grossSetupCostPerUnit = labRunCostPerUnit; // only labor for ops

  const contrFixedSetupCostTotal = labSetupCostHrlyTotal;
  //   const burFixedSetupCostTotal = burSetupCostPerHr * basisRunHrs;
  //   const grossFixedSetupCostTotal =
  //     contrFixedSetupCostTotal + burFixedSetupCostTotal;
  const grossSetupCostByUnitTotal = grossSetupCostPerUnit * qty;

  const grossSetupCostTotal =
    grossSetupCostHrlyTotal + grossSetupCostByUnitTotal;

  /* --- Service Costs --- */

  const totalOpSrvCost = Math.max(
    srvBaseCharge + srvChargePerUnit * qty,
    srvMinCharge,
  );

  // true fixed costs
  const totalContrbFixedCost = grossSetupCostTotal;
  const totalFixedCost = grossSetupCostTotal + burCostPerOperation;

  // op split
  const opSplit: CostCalculations = calcCosts({
    laborCost: labRunCostHrlyTotal + labSetupCostHrlyTotal,
    materialCost: 0,
    serviceCost: totalOpSrvCost,
    burdenCost:
      burRunCostHrlyTotal + burSetupCostHrlyTotal + burCostPerOperation,
  });

  // req split
  const reqSplit = reqInputs
    .map(calcCosts)
    .reduce(aggCostCalcs, zeroCostCalcs());

  // combined totals
  const totalMatCost = reqSplit.materialCost;
  const totalLabCost = opSplit.laborCost + reqSplit.laborCost;
  const totalSrvCost = totalOpSrvCost + reqSplit.serviceCost;
  const totalBurCost = opSplit.burdenCost + reqSplit.burdenCost;

  return {
    ...calcCosts({
      laborCost: totalLabCost,
      materialCost: totalMatCost,
      serviceCost: totalSrvCost,
      burdenCost: totalBurCost,
    }),
    opSplit,
    reqSplit,
  };
};

/* --- Utils --- */

const calcTotals = (perRate: number[], rateTotal: number): number[] => {
  return perRate.map((rate) => rate * rateTotal);
};
